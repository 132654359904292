


import React from "react";
import Team from '../Components/Team'
import { IoIosArrowForward } from "react-icons/io";
import Timelinelist from '../Components/Timelinelist';


import './AboutUs.css'
import AdminBlock from '../Components/AdminBlock'
import GroupImage from "../Components/GroupImage";
import GroupImage2 from '../Components/GroupImage2'
import GroupImage3 from "../Components/GroupImage3";
import GroupImage4 from "../Components/GroupImage4";

const Services = () => {
  return (
    <div>
      <div
        data-elementor-type="wp-page"
        data-elementor-id="2841"
        class="elementor elementor-2841"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-f6eae39 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="f6eae39"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-no">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7a5687d"
              data-id="7a5687d"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-72e11df elementor-widget elementor-widget-wpsection_main_banner"
                  data-id="72e11df"
                  data-element_type="widget"
                  data-widget_type="wpsection_main_banner.default"
                >
                  <div class="elementor-widget-container">
                    <section class="breadcrumb-area">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="breadcrumbs">
                              <h1><b>ABOUT US</b></h1>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="breadcrumb-botton">
                        <div class="container">
                          <div class="row">
                            <div class="col-md-12">
                              <ul class="left pull-left">
                                <li>
                                  <a href="/">Home </a>
                                </li>
                                <li>
                                  <IoIosArrowForward/>
                                </li>
                                <li class="active">About Us</li>
                              </ul>
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-ac0c4ef container elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="ac0c4ef"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-no">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7061f6f"
              data-id="7061f6f"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-663ca77 elementor-widget elementor-widget-heading"
                  data-id="663ca77"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <AdminBlock/>
                  <br/>
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default" style={{textAlign:"center"}}>
                      About DIEA
                    </h2>
                    <div class="elementor-element elementor-element-b88e1b2 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="b88e1b2" data-element_type="widget" data-widget_type="divider.default"><div class="elementor-widget-container"><span class="elementor-divider-separator"></span></div></div>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-46cb8ea elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                  data-id="46cb8ea"
                  data-element_type="widget"
                  data-widget_type="divider.default"
                >
                  {/* <div class="elementor-widget-container">
                    <div class="elementor-divider"> */}
                      <span class="elementor-divider-separator"></span>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div>
          </div> */}
        </section>

        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-bfaeb1e container elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="bfaeb1e"
          data-element_type="section"
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-no fadeInUp" data-wow-delay="0.1s">
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-bbcaf11 container"
              data-id="bbcaf11"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-31cd126 elementor-widget__width-auto elementor-widget elementor-widget-image"
                  data-id="31cd126"
                  data-element_type="widget"
                  data-widget_type="image.default"
                >
                  <div class="elementor-widget-container">
                    <img
                      fetchpriority="high"
                      decoding="async"
                      width="570"
                      height="345"
                      // src="http://diea.in/img/Gallery/d3.jpeg"
                           src="./images/ab2.jpg"
                      class="attachment-full size-full wp-image-357"
                      style={{transition: 'transform 0.6s',borderRadius:"30px"}}
                      onMouseOver={(e) => e.target.style.transform = 'scale(1.1)'}
                      onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
                      alt=""
                    />
                     <p  class="responsive_about_industry animate__animated animate__fadeInUp animate__delay-2s" style={{marginTop:"24px"}}> KSSIDC was set up to promote small-scale industries. The need to form an industrial estate for ITI led to Dyavasandra's selection,
                     with proximity to ITI and the developing Whitefield road. 
                    Particularly the Whitefield road was also developing into a booming industrial suburb of Bangalore.Thus Dyavasandra Industrial Estate was initially developed in the years between 1968 and 1977 with only 36 industrial sheds that were designated exclusively to cater to the needs of ITI.
             Hence it popularly became ITI Ancillary Industrial Estate.This is the second oldest Industrial Estate in Bangalore next only to Rajajinagar Industrial Estate.
             Gradually, the KSSIDC started building more industrial sheds and attracted an assortment of industrial units wherein manufacturing units started making a lot of products not only for the domestic market but also for exports. 
             By the turn of the new millennium (2000), Dyavasandra Industrial Estate had more than 150 Industrial sheds/plots housing more than 160 manufacturing units. From Electronics to auto parts, readymade garments to paper products, 
             various types of products are being manufactured in our Estate. Estate generates 7500 to 8000 employment. A huge contributor to the exchequer. As the industrial activities picked up, the industrial estate started facing a lot of issues by the entrepreneurs. 
             Issues like power supply, infrastructure, antisocial activities like theft, robbery, etc., 
             safety of the workers, and other social problems needed to be addressed.
              
             </p>
             <GroupImage/>
             <p class="responsive_about_industry animate__animated animate__fadeInUp animate__delay-3s" style={{marginTop:"24px"}}>
             DIEA introduced a security system to man the exit, entry points
              as well as night patrol all over the Estate.Consequent to neglect and apathy of the Municipal authorities, the Association took it upon itself to provide a lot of services to members. It embarked on the collection and disposal of garbage, regular cleaning of the Estate, etc. The Association is ever alert in interacting with the service providers mainly power supply and distribution company: BESCOM. On a daily basis, the Association interacts with BESCOM even for a minor fault and interruptions in power supply 
              and gets the power supply resumed with the help of their officials. 
             </p>
             <GroupImage3/>
             <p class="responsive_about_industry animate__animated animate__fadeInUp animate__delay-4s" style={{marginTop:"24px"}}>  As recently as in the year 2022, the Association was responsible for taking upon itself the huge task of the laying pipeline project. This huge project was necessitated due to the expansion of the railway network to accommodate suburban railway.The Railways 
               informed that they are increasing the level of the earth to facilitate their project. This would have blocked the natural flow of our stormwater/UGD. In spite of trying with the concerned municipal authorities such as BBMP, BWSSB to initiate suitable steps to 
               revamp the UGD/stormwater drains but it was not fruitful. Ultimately with the support and cooperation of members who generously helped in taking up this project as a permanent solution. The project cost was Rs.34.00 lakhs, and it is one of our major landmark 
               achievements.</p>
            
             
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c9304e8"
              data-id="c9304e8"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-436bf97 elementor-widget_width-initial elementor-widget-tablet_width-initial elementor-widget elementor-widget-heading"
                  data-id="436bf97"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default responsive_aboutus animate__animated animate__fadeInUp animate__delay-5s" style={{color:"black",marginRight:"15px"}}>
                    Welcome to Devasandra Industrial Estate, a prominent industrial zone nestled in the vibrant city of Bangalore, India. 
Boasting a strategic location and excellent infrastructure,
 Devasandra Industrial Estate serves as a dynamic ecosystem for businesses across various sectors.
Our industrial estate offers a wide range of facilities and amenities tailored to meet the diverse needs of modern industries.
 {/* From spacious manufacturing units to state-of-the-art warehousing facilities, we provide a conducive environment for businesses to thrive and expand. */}
                    </h2>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-65c9cfd elementor-widget elementor-widget-heading"
                  data-id="65c9cfd"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default" style={{fontSize:'25px',marginTop:'-24px'}}>
                     <b>History in Words</b> 
                    </h2>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-448900c elementor-widget__width-initial elementor-widget elementor-widget-heading"
                  data-id="448900c"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <p class="elementor-heading-title elementor-size-default animate__animated animate__fadeInUp animate__delay-6s" style={{color:"black"}}>
                    {/* KSSIDC was set up to promote small-scale industries. The need to form an industrial estate for ITI led to Dyavasandra's selection,
                     with proximity to ITI and the developing Whitefield road. 
                    Particularly the Whitefield road was also developing into a booming industrial suburb of Bangalore. */}
                    Dyavasandra, The village outside Bangalore, neighbour to Krishnarajapura.
                     Krishnarajapura was home to the public sector giant: Indian Telephone Industries Ltd (ITI). 
                    ITI was the only communications product manufacturing company in India during the 60s.
                    Political leaderships were taking steps to industrialize the State and Country. Opportunities were huge.
                    </p>
                  </div>
                  <Timelinelist />
                  <p  class="responsive_about_industry animate__animated animate__fadeInUp animate__delay-7s" style={{marginTop:"30px"}}>Few entrepreneurs started thinking that it would be better if we address the issues 
             and grievances collectively; remedies and solutions can be obtained speedily. 
             Thus in the year 2000, DYAVASANDRA INDUSTRIAL ESTATE ASSOCIATION (DIEA) was born.
            Sri R. 
             Gangadhar was instrumental in bringing the right thinking people together and form the Association. 
             He was our Founder President. Since then DIEA has achieved a lot of landmarks. The main objective was 
             providing safety to our member units. 
              
             </p><GroupImage2/>
             <p class="responsive_about_industry animate__animated animate__fadeInUp animate__delay-8s" style={{marginTop:"24px"}}>
             The DIEA is instrumental in persuading KSSIDC between the years 2006-08 to undertake revamping of stormwater drains, laying of cement roads in the rear side and allied works under ASIDE scheme.
              Further DIEA constructed the rear compound wall which is a living testimony even today and stands tall between trespassers and the safety of our member units. This helped in preventing thefts, robbery, and anti-social activities. During Covid 19 pandemic, the
               Association proactively took several measures to stop and contain the spreading. Introduced thermal screening of every person entering the Estate, organized several vaccination camps, etc. By and large, our members escaped becoming victims of the pandemic.
              
             </p> <GroupImage4/>
                </div>
               
              
               
              </div>
            </div>
            
            
          </div>
          <p style={{textAlign:"justify",color:"black",paddingLeft:"16px",paddingRight:"16px",fontSize:"17px"}}> Even today, the Association continues to be vibrant and proactive, engages in the activities that benefit its members, interacts with statutory agencies, provides selfless service to its members. Being a follower of democratic traditions, every 
               year the Association chooses its managing committee, office bearers who lead and work sincerely for the welfare of its members.</p>
             
        </section>

         

        <Team />
      </div>
    </div>
  );
};

export default Services;
